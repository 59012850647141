import {Component} from '@angular/core';
import {
  Exemption_8_1,
  Exemption_8_1_FuelType,
  Vessel
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {sendQuery} from "../../../../../common/utils";

@Component({
  selector: 'app-visit-exemptions-exemption-8-1',
  templateUrl: './exemption_8_1.component.html',
  styleUrls: ['./exemption_8_1.component.css']
})
export class Exemption_8_1Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_8_1;

  portVisitUtils = PortvisitUtils;

  vessel: Vessel;

  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});

  fuelTypes: Exemption_8_1_FuelType[] = [
    null,
    Exemption_8_1_FuelType.LNG,
    Exemption_8_1_FuelType.METHANOL,
    Exemption_8_1_FuelType.AMMONIA,
    Exemption_8_1_FuelType.HYDROGEN
  ];

  fuelTypesFormatter = (fuelType: Exemption_8_1_FuelType) => {
    switch (fuelType) {
      case null:
        return 'None';
      case "LNG":
        return 'LNG';
      case "METHANOL":
        return 'Methanol';
      case "AMMONIA":
        return 'Ammonia';
      case "HYDROGEN":
        return 'Hydrogen';
    }
  };

  vesselFormatter = (vessel: Vessel) => {
    return vessel.name + ' – ' + vessel.imoCode;
  };

  onVesselChange(vessel: Vessel) {
    this.exemption['vesselName'] = vessel.name;
    this.exemption['vesselIMO'] = vessel.imoCode;
  }
}
