<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <app-select [(ngModel)]="exemption['reasonType']" id="reasonType" [options]="reasonTypes"
                      [formatter]="reasonTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Reason" *ngIf="exemption['reasonType'] === 'OTHER'">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST'" label="Perform pull test">
          <app-yes-no [(ngModel)]="exemption['pullTest']" id="pullTest" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST' && exemption['pullTest']" label="Planned pull force (Kn)" >
          <input [(ngModel)]="exemption['pullTestForce']" id="plannedPullForce" type="number" maxlength="10" class="form-control" required>
        </app-form-group>
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST' && exemption['pullTest']" label="Pull test from">
          <app-date-field [(ngModel)]="exemption['pullTestFrom']" id="pullTestFrom" required></app-date-field>
        </app-form-group>
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST' && exemption['pullTest']" label="Pull test to">
          <app-date-field [(ngModel)]="exemption['pullTestTo']" id="pullTestTo" required></app-date-field>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST'" label="Perform push test">
          <app-yes-no [(ngModel)]="exemption['pushTest']" id="pushTest" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST' && exemption['pushTest']" label="Planned push force (Kn)" >
          <input [(ngModel)]="exemption['pushTestForce']" id="plannedPushForce" type="number" maxlength="10" class="form-control" required>
        </app-form-group>
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST' && exemption['pushTest']" label="Push test from">
          <app-date-field [(ngModel)]="exemption['pushTestFrom']" id="pushTestFrom" required></app-date-field>
        </app-form-group>
        <app-form-group *ngIf="exemption['reasonType'] === 'PUSH_PULL_TEST' && exemption['pushTest']" label="Push test to">
          <app-date-field [(ngModel)]="exemption['pushTestTo']" id="pushTestTo" required></app-date-field>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
  </ng-container>
</ng-container>
