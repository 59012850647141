<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Product name">
          <input [(ngModel)]="exemption['productName']" id="productName" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Quantity (ton)">
          <input [(ngModel)]="exemption['quantity']" id="quantity" type="number" maxlength="32" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Method of transshipment">
          <app-select [(ngModel)]="exemption['transshipmentType']" id="transshipmentType" [options]="transshipmentTypes"
                      [formatter]="transshipmentTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['transshipmentType'] === 'OTHER'" label="Other method of transshipment">
          <input [(ngModel)]="exemption['transshipmentTypeOther']" id="transshipmentTypeOther" type="text" maxlength="256" class="form-control">
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Temporary storage on quayside">
          <app-select [(ngModel)]="exemption['storageType']" id="storageType" [options]="storageTypes"
                      [formatter]="storageTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
       </div>
    </div>

    <div class="row fieldset">
      <div class="col-md" *ngIf="exemption['storageType'] === 'TEMPORARY'">
        <app-form-group label="From">
          <app-date-field [(ngModel)]="exemption['from']" id="from" required></app-date-field>
        </app-form-group>
        <app-form-group label="To">
          <app-date-field [(ngModel)]="exemption['to']" id="to" required></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Truck max. axle weight (ton)">
          <input [(ngModel)]="exemption['maxAxleWeight']" id="maxAxleWeight" type="number" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Use of forklift ?">
          <app-yes-no [(ngModel)]="exemption['useOfForkLift']" id="useOfForkLift" required></app-yes-no>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Expected quayside load (ton/m2)">
          <input [(ngModel)]="exemption['expectedQuaysideLoad']" id="expectedQuaysideLoad" type="number" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Expected tensile strength of mooring lines">
          <input [(ngModel)]="exemption['expectedTensileStrength']" id="expectedTensileStrength" type="text" maxlength="256" class="form-control">
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
