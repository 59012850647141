import {NgModule} from "@angular/core";
import {CommandGateway} from "./command-gateway";
import {QueryGateway} from "./query-gateway";
import {EventGateway} from "./event-gateway";
import {HandlerRegistry} from "./handler-registry.service";

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    HandlerRegistry,
    CommandGateway,
    QueryGateway,
    EventGateway,
  ]
})
export class HandlersModule {

}
