<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Vessel name/IMO registration</label>
            <div class="tooltipContent">Name of vessel receiving cargo being pumped over</div>
          </app-info>
          <app-search [(ngModel)]="vessel" (ngModelChange)="onVesselChange(vessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                      [minCharacters]="3" dataKey="imoCode" id="vessel">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Second vessel name">
          <input [(ngModel)]="exemption['vesselName']" id="vesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
        <app-form-group label="Second vessel IMO">
          <input [(ngModel)]="exemption['vesselIMO']" id="vesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Type of berth">
          <app-select [(ngModel)]="exemption['berthType']" id="berthType" [options]="berthTypes"
                      [formatter]="berthTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['berthType'] === 'BUOY_MOORING' || exemption['berthType'] === 'POLE_BERTHING'"
                        label="Communication between vessels, shore and authorities and familiarity with local conditions and regulations is sufficient and demonstrable">
          <app-yes-no [(ngModel)]="exemption['obligationCommunication']" id="obligationCommunication" required></app-yes-no>
        </app-form-group>
        <app-form-group *ngIf="exemption['berthType'] === 'BUOY_MOORING' || exemption['berthType'] === 'POLE_BERTHING'"
                        label="The captain has demonstrably appointed a suitably competent person-in-charge who is capable (internally or externally) of performing the duties of the STS superintendent as described in the STS transfer guide (Reg. 1.6)">
          <app-yes-no [(ngModel)]="exemption['obligationPersonInCharge']" id="obligationPersonInCharge" required></app-yes-no>
        </app-form-group>
        <app-form-group *ngIf="exemption['berthType'] === 'BUOY_MOORING' || exemption['berthType'] === 'POLE_BERTHING'"
                        label="Transhipment takes place exclusively in a petroleumhaven or under a dynamic petroleum-harbour regime">
          <app-yes-no [(ngModel)]="exemption['obligationTransshipmentPetroleum']" id="obligationTransshipmentPetroleum" required></app-yes-no>
        </app-form-group>
        <app-form-group *ngIf="exemption['berthType'] === 'BUOY_MOORING' || exemption['berthType'] === 'POLE_BERTHING'"
                        label="Transhipment does not take place in buoy mooring 50, 51, 52 and 61 (2nd, 1st and 3rd petroleumhaven)">
          <app-yes-no [(ngModel)]="exemption['obligationTransshipmentNotInBuoy']" id="obligationTransshipmentNotInBuoy" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['berthType'] === 'BUOY_MOORING' || exemption['berthType'] === 'POLE_BERTHING'"
                        label="These does not relate to toxic gases">
          <app-yes-no [(ngModel)]="exemption['obligationNotRelatedToxicGases']" id="obligationNotRelatedToxicGases" required></app-yes-no>
        </app-form-group>
        <app-form-group *ngIf="exemption['berthType'] === 'BUOY_MOORING' || exemption['berthType'] === 'POLE_BERTHING'"
                        label="These are LNG, LPG, propylene, propane, butene, butadiene, isobutene, butane or isobutane, or mixtures of these substances">
          <app-yes-no [(ngModel)]="exemption['obligationGases']" id="obligationGases" required></app-yes-no>
        </app-form-group>
        <app-form-group *ngIf="exemption['berthType'] === 'BUOY_MOORING' || exemption['berthType'] === 'POLE_BERTHING'"
                        label="Ship-to-ship transhipment of gas and other operations take place as per the provisions of the ‘ship-to-ship transhipment guide for petroleum, chemicals and liquefied gases’">
          <app-yes-no [(ngModel)]="exemption['obligationShipToShip']" id="obligationShipToShip" required></app-yes-no>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Substance (Proper shipping name)">
          <app-search [(ngModel)]="dangerousGoods" dataKey="name"
                      (ngModelChange)="onDangerousGoodsChange(dangerousGoods)"
                      [inputFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      [searchFunction]="portVisitUtils.findDangerousGoods"
                      [resultFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      placeholder="Find by name or UN code">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Substance name">
          <textarea [(ngModel)]="exemption['substanceName']" id="substanceName" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
      </div>
      <div class="col-md">
        <app-form-group label="UN or VN number">
          <input [(ngModel)]="exemption['unVnNumber']" id="unVnNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
