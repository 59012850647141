import {Component, ElementRef, inject, OnInit} from '@angular/core';
import {ConsignmentUtils, GoodsItemSummaryWithHouseConsignments} from "../../consignment.utils";
import {
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3ContainerProcess,
  IE3GetConsignment,
  IE3GetContainerProcess,
  IE3GoodsItem,
  IE3GoodsPlacement,
  IE3SaveConsignment,
  IE3SizeType,
  IE3TransportEquipment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  formDataSaved,
  openConfirmationModalWithCallback,
  openEditModal,
  sendCommand,
  sendQuery
} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../master-consignment-details/master-consignment-details.component";
import {
  HouseConsignmentDetailsComponent,
  HouseConsignmentDetailsComponentData
} from "../house-consignment-details/house-consignment-details.component";
import {ContainerUtils} from "../../../containers/container.utils";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../../common/modal/modal-confirm.component";
import {ConsignmentRules} from "../../consignment.rules";
import {publishHandlerEvent} from "../../../../common/handlers/app-common-utils";

@Component({
  selector: 'app-equipment-consignment-details',
  templateUrl: './equipment-consignment-details.component.html',
  styleUrls: ['./equipment-consignment-details.component.scss']
})
export class EquipmentConsignmentDetailsComponent implements OnInit {
  private elementRef: ElementRef = inject(ElementRef);
  utils = ConsignmentUtils;
  portVisitUtils = PortvisitUtils;

  consignmentProcess: IE3ConsignmentProcess;
  houseConsignment: IE3ConsignmentHouseLevel;
  equipment: IE3TransportEquipment;
  data: EquipmentConsignmentDetailsComponentData;
  isNewEquipment: boolean;
  editMode: boolean;
  containerProcess: IE3ContainerProcess;

  goodsItems: GoodsItemSummaryWithHouseConsignments[];
  placement: IE3GoodsPlacement = {};

  sealIdentifiersFormatter = (values: string[]) => values?.join(" | ") || null;

  protected emptyIndicatorFormatter = (v: boolean) => v ? 'Empty' : 'Not empty';

  ngOnInit(): void {
    if (this.data.cachedConsignmentProcess) {
      this.setConsignment(this.data.cachedConsignmentProcess, true);
    } else {
      sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
        consignmentProcessId: this.data.consignmentProcessId
      }, {caching: false}).subscribe((c: IE3ConsignmentProcess) => this.setConsignment(c, true));
    }
  }

  remove = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        ConsignmentUtils.allGoodsItems(this.consignmentProcess).forEach(g => {
          g.goodsPlacements = g.goodsPlacements.filter(p => p.containerIdentificationNumber !== this.equipment.containerIdentificationNumber);
        });
        delete this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap[this.equipment.containerIdentificationNumber];
        this.elementRef.nativeElement.dispatchEvent(new Event("change", {bubbles: true, cancelable: true}));
        this.openConsignment();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Remove equipment",
      message: this.houseConsignment
        ? `You are about to remove equipment: ${this.equipment.containerIdentificationNumber} from house B/L ${this.houseConsignment.consignmentNumber}`
        : `You are about to remove equipment: ${this.equipment.containerIdentificationNumber} from master B/L ${this.consignmentProcess.consignmentMasterLevel.consignmentNumber}`,
      question: "Are you sure that you want to remove this equipment?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  }

  toggleEdit = () => {
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  saveOrDeclare = () => {
    if (this.isValid && this.processModel(this.hasBeenDeclared)) {
      if (this.hasBeenDeclared) {
        if (this.allowedToDeclare) {
          if (checkValidity(this.elementRef)) {
            ConsignmentUtils.declareConsignment(this.consignmentProcess);
          }
        } else {
          this.toggleEdit();
        }
      } else {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignment: this.consignmentProcess.consignmentMasterLevel,
          filing: this.consignmentProcess.filing
        }, () => {
          formDataSaved();
          this.toggleEdit();
        });
      }
    }
  }

  get allowedToDeclare(): boolean {
    return ConsignmentRules.isAllowedToDeclare(this.consignmentProcess);
  }

  get hasBeenDeclared(): boolean {
    return ConsignmentUtils.hasBeenDeclared(this.consignmentProcess);
  }

  private openConsignment() {
    if (this.houseConsignment) {
      this.openHouseConsignment();
    } else {
      this.openMasterConsignment();
    }
  }

  processModelAndOpenMasterConsignment = () => {
    this.processModel(false);
    this.openMasterConsignment();
  }

  private openMasterConsignment = () => {
    publishHandlerEvent('cachedConsignmentUpdated', this.consignmentProcess);
    openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
      cachedConsignmentProcess: this.consignmentProcess
    }, {
      warnOnClose: true,
      currentToStack: true
    });
  }

  processModelAndOpenHouseConsignment = () => {
    this.processModel(false);
    this.openHouseConsignment();
  }

  private openHouseConsignment = () => {
    publishHandlerEvent('cachedConsignmentUpdated', this.consignmentProcess);
    openEditModal(HouseConsignmentDetailsComponent, <HouseConsignmentDetailsComponentData>{
      consignmentProcessId: this.consignmentProcess.consignmentProcessId,
      consignmentNumber: this.houseConsignment.consignmentNumber,
      cachedConsignmentProcess: this.consignmentProcess
    }, {
      warnOnClose: true,
      currentToStack: true
    });
  }

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  get oversize() {
    return this.equipment.oversize != null;
  }

  set oversize(oversize: boolean) {
    this.equipment.oversize = oversize ? {} : null;
  }

  get isValid(): boolean {
    return checkValidity(this.elementRef);
  }

  get terminalDataOversize(): boolean {
    return this.containerProcess?.terminalData?.oversize != null && !!Object.values(this.containerProcess?.terminalData?.oversize).filter(a => a).length;
  }

  compareSizeTypeByCode = (value: IE3SizeType, compare: IE3SizeType) => value?.code !== compare?.code;

  processModel = (registerErrors: boolean) => ConsignmentUtils.validateEquipment(
    this.consignmentProcess, this.equipment, registerErrors, this.isNewEquipment, this.placement,
    this.houseConsignment, this.data.goodsItem);

  private createEquipment = (): IE3TransportEquipment => {
    return {
      sealIdentifiers: [],
      empty: false
    };
  }

  private setConsignment = (c: IE3ConsignmentProcess, updateEditMode?: boolean) => {
    this.consignmentProcess = c;
    if (updateEditMode && !ConsignmentUtils.hasBeenDeclared(c) && this.isEditable) {
      this.editMode = true;
    }
    this.houseConsignment = c.consignmentMasterLevel.consignmentsHouseLevel
      .find(c => c.consignmentNumber === this.data.houseConsignmentNumber);
    this.equipment = c.consignmentMasterLevel.transportEquipmentMap[this.data.equipmentNumber];
    if (!this.equipment) {
      this.isNewEquipment = true;
      this.editMode = true;
      this.equipment = this.createEquipment();
    } else {
      if (this.consignmentProcess.filing.crn) {
        sendQuery("com.portbase.bezoekschip.common.api.consignments.containers.queries.GetContainerProcess", <IE3GetContainerProcess>{
          containerProcessId: ContainerUtils.constructContainerProcessId(this.consignmentProcess.filing.crn, this.equipment.containerIdentificationNumber)
        }, {caching: false}).subscribe((c: IE3ContainerProcess) => this.containerProcess = c);
      }
    }
    const goodsItems = (this.houseConsignment
      ? this.houseConsignment.goodsItems
      : ConsignmentUtils.allGoodsItems(this.consignmentProcess)).filter(g => g.goodsPlacements.find(
      p => p.containerIdentificationNumber === this.equipment.containerIdentificationNumber));
    this.goodsItems = ConsignmentUtils.toConsignmentGoodsItemsSummaries(goodsItems)
      .map(g => (<GoodsItemSummaryWithHouseConsignments>{
        goodsItem: g,
        houseConsignments: this.houseConsignment ? [] : this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
          .filter(h => h.goodsItems.some(
            hg => hg.commodity?.commodityCode?.harmonizedSystemSubHeadingCode === g.commodity?.commodityCode?.harmonizedSystemSubHeadingCode
              && hg.goodsPlacements.some(gp => gp.containerIdentificationNumber === this.equipment.containerIdentificationNumber))
          )
          .map(h => h.consignmentNumber),
        clearance: ConsignmentUtils.getClearanceOfGoodsItem(this.consignmentProcess.status, g.goodsItemNumber)
      }));
  }

  get isDutchPort() {
    return ConsignmentRules.isDutchPort(this.consignmentProcess);
  }

  get isShortLanded() {
    return ConsignmentRules.isShortLanded(this.consignmentProcess, this.equipment.containerIdentificationNumber);
  }
}

export interface EquipmentConsignmentDetailsComponentData {
  consignmentProcessId: string;
  equipmentNumber: string;
  houseConsignmentNumber: string;
  goodsItem: IE3GoodsItem;
  cachedConsignmentProcess: IE3ConsignmentProcess;
}
