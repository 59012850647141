import {filterByTerm, lodash, sendQuery, toTitleCase} from '../common/utils';
import {
  BezoekschipOrganisation,
  CargoCurrency,
  Carrier,
  Consignment,
  ContainerType,
  Country,
  CustomsOffice,
  CustomsProcess,
  CustomsStatus,
  DangerInformation, DangerousGoods,
  FindBulkAuthorisations,
  FindConsignments,
  FindGuarantees,
  FindInlandOperatorsByEanCode,
  FindOrganisations,
  FindTerminals,
  FindTransitParties,
  FindTransitTemplates,
  Fumigant,
  GoodDataUnion,
  GoodsClassification,
  GoodsDocumentType,
  Guarantee,
  GuaranteeType,
  IE3ConsignmentsResult,
  InlandOperator,
  Location,
  ModeOfTransport,
  MovementType,
  PackageCategory,
  PackageType,
  PackingGroup,
  Party,
  Port,
  ReleaseToParty,
  SearchVisits,
  SecurityCircumstances,
  SecurityPaymentMethod,
  SizeType,
  Stevedore,
  StorageType,
  TankStatus,
  Terminal,
  TransitDocumentType,
  TransitInspectionType,
  TransitParty,
  TransitTemplate,
  TransitType,
  TransportIndex,
  Visit,
  VisitOverview,
  VisitSummary,
  WarehouseLicense
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-details/visit-context';
import {map, mergeMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {AppContext} from '../app-context';
import moment from 'moment';
import {QueryOptions} from "../common/query-gateway";
import pilots from "@portbase/bezoekschip-service-typescriptmodels/refdata/pilots.json";
import tugboats from "@portbase/bezoekschip-service-typescriptmodels/refdata/tugboats.json";
import boatmen from "@portbase/bezoekschip-service-typescriptmodels/refdata/boatmen.json";
import {MiddleEllipsisPipe} from "../common/middle-ellipsis.pipe";

export class PortvisitUtils {

  private static containerNumberRegex = RegExp(/[A-Z]{4}[0-9]{7}/);

  static areNauticalServicesApplicable = portUnCode =>
    this.hasPilots(portUnCode) || this.hasTugboats(portUnCode) || this.hasBoatmen(portUnCode);
  static hasPilots = portUnCode => pilots[portUnCode] && pilots[portUnCode].length > 0;
  static hasTugboats = portUnCode => tugboats[portUnCode] && tugboats[portUnCode].length > 0;
  static hasBoatmen = portUnCode => boatmen[portUnCode] && boatmen[portUnCode].length > 0;

  static getTransitOffices = () => sendQuery('com.portbase.bezoekschip.common.api.visit.GetTransitOfficesOfDeparture', {});
  static findTransitOffices = term => sendQuery("com.portbase.bezoekschip.common.api.visit.GetTransitOffices", {})
    .pipe(map(values => this.filterValuesByTerm(values, term)));

  static transitOfficesFormatter = (value: CustomsOffice) => value ? value.unCode + ' – ' + value.name : '';
  static transitOfficesCompareFormatter = (value: CustomsOffice) => value ? value.unCode : '';

  private static filterValuesByTerm(values, term) {
    const filtered = values.filter(filterByTerm(term));
    return filtered.slice(0, Math.min(filtered.length, 20));
  }

  static trackByIndex<T>(index: number, s: T) {
    return index;
  }

  static enumFormatter: (value: string) => string = v => {
    return toTitleCase(v).replace(/_/g, " ");
  };

  static getGuaranteeTypes: () => Observable<GuaranteeType[]> = () => sendQuery('com.portbase.bezoekschip.common.api.visit.GetGuaranteeTypes', {}) as Observable<GuaranteeType[]>;
  static guaranteeTypeFormatter = (value: GuaranteeType) => value ? value.code + ' – ' + value.description : '';
  static guaranteeTypeFormatterSmall = (value: GuaranteeType) => value ? value.description : '';

  static getGuarantees : ((org: string) => Observable<Guarantee[]>) = (organisationShortName: string) => sendQuery("com.portbase.bezoekschip.common.api.transit.FindGuarantees", <FindGuarantees>{
    ownerShortName: AppContext.isAdmin() && organisationShortName ?
      organisationShortName : AppContext.userProfile.organisation?.shortName
  });


  static transitTypes = () => sendQuery('com.portbase.bezoekschip.common.api.visit.GetTransitTypes', {});
  static transitTypeFormatter = (value: TransitType) => value ? (value.description? value.code + ' – ' + value.description : value.code) : '';
  static transitTypeCompareFormatter = (value: TransitType) => value ? value.code : '';

  static dischargeResultTerminals = ["INTERFORESTRTM", "RST", "RSTZ", "ECTDELTA", "EUROMAX", "APMII", "APMRTM", "RWG",
    "DFDS", "FELISON", "MEO", "CRORTM", "CROVLI", "PONSFT", "STENAEUR", "STENAHVH", "KLOOSTERBOERVLI", "BROEKMANDISTRI", "ZEELOG", "CCT"];
  static modesOfTransport: ModeOfTransport[] = [null, ModeOfTransport.AIR_TRANSPORT, ModeOfTransport.FIXED_TRANSPORT,
    ModeOfTransport.INLAND_WATER_TRANSPORT, ModeOfTransport.MAIL, ModeOfTransport.MARITIME_TRANSPORT,
    ModeOfTransport.MULTIMODAL_TRANSPORT, ModeOfTransport.NOT_APPLICABLE, ModeOfTransport.NOT_SPECIFIED,
    ModeOfTransport.RAIL_TRANSPORT, ModeOfTransport.ROAD_TRANSPORT];
  static transitModesOfTransport: ModeOfTransport[] = [null, ModeOfTransport.AIR_TRANSPORT,
    ModeOfTransport.FIXED_TRANSPORT, ModeOfTransport.INLAND_WATER_TRANSPORT, ModeOfTransport.MAIL,
    ModeOfTransport.MARITIME_TRANSPORT, ModeOfTransport.MULTIMODAL_TRANSPORT, ModeOfTransport.NOT_SPECIFIED,
    ModeOfTransport.RAIL_TRANSPORT, ModeOfTransport.ROAD_TRANSPORT];
  static transitInspectionTypes: TransitInspectionType[] = [null, TransitInspectionType.A1, TransitInspectionType.A2,
    TransitInspectionType.A3, TransitInspectionType.A4, TransitInspectionType.A5, TransitInspectionType.B1];
  static securityCircumstances: SecurityCircumstances[] = [null, SecurityCircumstances.post_and_express_shipments,
    SecurityCircumstances.victualling_of_ships_and_aircraft, SecurityCircumstances.road_transport,
    SecurityCircumstances.rail_transport, SecurityCircumstances.authorized_economic_operators];
  static securityPaymentMethods: SecurityPaymentMethod[] = [null, SecurityPaymentMethod.cash_payment,
    SecurityPaymentMethod.creditcard_payment, SecurityPaymentMethod.payment_by_cheque,
    SecurityPaymentMethod.other_eg_automatic_deduction, SecurityPaymentMethod.electronic_payment,
    SecurityPaymentMethod.account_customer_with_carrier, SecurityPaymentMethod.not_paid_in_advance];
  static movementTypes: MovementType[] = [null, MovementType.DOOR_TO_DOOR, MovementType.DOOR_TO_PIER,
    MovementType.PIER_TO_DOOR, MovementType.PIER_TO_PIER];
  static customsProcesses: CustomsProcess[] = [null, CustomsProcess.SEA_IN_SEA_OUT, CustomsProcess.MILITARY,
    CustomsProcess.EMPTY_RETURN_PACKAGING];
  static customsProcessFormatter = (value: CustomsProcess) => value ? PortvisitUtils.enumFormatter(value) + ' – ' + PortvisitUtils.getCustomsProcessCode(value) : '';
  static getCustomsProcessCode = process => {
    switch (process) {
      case 'SEA_IN_SEA_OUT':
        return '27';
      case 'MILITARY':
        return '302';
      case 'EMPTY_RETURN_PACKAGING':
        return 'P';
    }
  }
  static customsStatuses: CustomsStatus[] = [null, CustomsStatus.EU_COMMUNITY_GOODS,
    CustomsStatus.EU_COMMUNITY_GOODS_IN_TRANSHIPMENT, CustomsStatus.EU_PROCEDURE_T, CustomsStatus.EU_PROCEDURE_T1,
    CustomsStatus.EU_PROCEDURE_T2, CustomsStatus.EU_PROCEDURE_T2F, CustomsStatus.GOODS_FROM_EVA_COUNTRIES];
  static customsStatusFormatter = (value: CustomsStatus) => value ? PortvisitUtils.enumFormatter(value) + ' – ' + PortvisitUtils.getCustomsStatusCode(value) : '';
  static getCustomsStatusCode = status => {
    switch (status) {
      case 'EU_COMMUNITY_GOODS':
        return 'C';
      case 'EU_COMMUNITY_GOODS_IN_TRANSHIPMENT':
        return 'N27';
      case 'EU_EMPTY_RETURN_PACKAGING':
        return 'NP';
      case 'EU_PROCEDURE_T':
        return 'T';
      case 'EU_PROCEDURE_T1':
        return 'T1';
      case 'EU_PROCEDURE_T2':
        return 'T2';
      case 'EU_PROCEDURE_T2F':
        return 'T2F';
      case 'GOODS_FROM_EVA_COUNTRIES':
        return 'TV';
    }
  }
  static findCountries = term => sendQuery("com.portbase.bezoekschip.common.api.visit.GetCountries", {})
    .pipe(map(values => this.filterValuesByTerm(values, term)));
  static findCountriesWithCustomsOffices = term => sendQuery("com.portbase.bezoekschip.common.api.visit.GetCountriesWithCustomsOffices", {})
    .pipe(map(values => this.filterValuesByTerm(values, term)));

  static findVisitsWithCargoEnabled = term => sendQuery("com.portbase.bezoekschip.common.api.visit.SearchVisits", <SearchVisits>{maxHits: 100, term: term})
    .pipe(map((overview: VisitOverview) => {
      const values: VisitSummary[] = [];
      values.push(...overview.expectedVisits);
      values.push(...overview.arrivedVisits);
      values.push(...overview.departedVisits);
      return values
        .filter(s => s.crn !== VisitContext.savedVisit.crn)
        .filter(s => {
          const cargoAgentStatus = s.cargoAgentStatuses[VisitContext.cargoImportModel.cargoDeclarant.shortName];
          return cargoAgentStatus && cargoAgentStatus.cargoImportEnabled;
        });
    }));
  static countryFormatter = (value: Country) => value ? (value.name ? value.name + ' – ' + value.code : value.code) : '';
  static countryCompareFormatter = (value: Country) => value ? value.code : '';
  static findWarehouseLicenseHolders = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindBulkAuthorisations",
    <FindBulkAuthorisations>{term: term}).pipe(map((values: BezoekschipOrganisation[]) => {
    if (values.length === 0) {
      return [{
        customsEORINumber: term,
        fullName: 'Select EORI number'
      }];
    }
    return values;
  }));
  static wareHouseLicenseFormatter = (value: BezoekschipOrganisation) => value ? value.fullName + ': ' + value.customsEORINumber : '';
  static findBulkAuthorisations = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindBulkAuthorisations",
    <FindBulkAuthorisations>{term: term});
  static bulkAuthorisationFormatter = (value: BezoekschipOrganisation) => value ? value.fullName + ' – ' + value.customsEORINumber : '';
  static findOrganisations = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindOrganisations',
    <FindOrganisations>{term: term});
  static findInlandOperatorsByEanCode = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindInlandOperatorsByEanCode',
    <FindInlandOperatorsByEanCode>{term: term});

  static findConsignments = (current: Consignment) => term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindConsignments", <FindConsignments>{
    cargoDeclarantShortName: VisitContext.cargoImportModel.cargoDeclarant.shortName, term: term
  })
    .pipe(map((values: Consignment[]) => {
      const localConsignments: Consignment[] = lodash.flatMap(VisitContext.cargoImportModel.manifests, m => m.consignments)
        .filter(filterByTerm(term));
      const candidates = lodash.uniqBy(localConsignments.concat(values), c => c.consignmentNumber)
        .filter(c => !current || current.consignmentNumber !== c.consignmentNumber);
      return candidates.slice(0, 20);
    }));

  static getVisitTerminals(visit: Visit = VisitContext.savedVisit): Observable<Terminal[]> {
    return of(visit.visitDeclaration.portVisit.berthVisits.filter(v => !!v.berth)
      .map(v => <Terminal>v.berth)).pipe(mergeMap(terminals => {
      return sendQuery("com.portbase.bezoekschip.common.api.visit.GetTerminals",
          {
            portUnCode: VisitContext.visit.portOfCall.port.locationUnCode,
            terminalCodes: terminals.map(t => t.terminalCode)
          }, {caching: true});
    }));
  }

  static findAllConsignments = term => sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.FindConsignments',
    {term: term}).pipe(map((result: IE3ConsignmentsResult) => {
      return result.consignments
  }));

  static formatReleaseToParty = (v : ReleaseToParty) => v ? `${v.name} (${v.customerReference})` : "";

  static getOrganisationByShortName = (shortName, options?: QueryOptions) => sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetOrganisationByShortName", {shortName: shortName}, options);
  static getCurrencies: Observable<CargoCurrency[]> = sendQuery("com.portbase.bezoekschip.common.api.visit.GetCurrencies", {});
  static currenciesFormatter = (value: CargoCurrency) => value ? value.unCode + (value.description ? ' (' + toTitleCase(value.description) + ')' : '') : '';
  static compactCurrenciesFormatter = (value: CargoCurrency) => value ? value.unCode : '';
  static findContainerOperators = term => {
    let trimmed = lodash.trim(term);
    return !trimmed ? of([]) : sendQuery("com.portbase.bezoekschip.common.api.visit.FindCarriers", {term: trimmed});
  };
  static containerOperatorFormatter = (carrier: Carrier) => carrier && carrier.name ? (carrier.scacCode ? carrier.scacCode + ' - ' : '') + carrier.name + (carrier.customsId ? ' - ' + carrier.customsId : '') : '';
  static carrierFormatter = (carrier: Carrier) => carrier && carrier.name ? (carrier.smdgCode ? carrier.smdgCode + ' - ' : '') + carrier.name : '';
  static inlandOperatorFormatter = (o: InlandOperator) => o ? o.name + ' (' + o.ean + ')' : '';
  static agentFormatter = (o: BezoekschipOrganisation) => o ? o.fullName + ' (' + o.shortName + ')' : '';
  static stevedoreFormatter = (o: Stevedore) => o ? o.fullName : '';

  static portAuthorityFormatter = (pa: BezoekschipOrganisation) => pa ? pa.fullName + ' [' + pa.shortName + ']' : '';
  static customsOfficeFormatter = (co: CustomsOffice) => co ? co.name + ' [' + co.unCode + ']' : '';

  static getPreviousPorts(): Port[] {
    return lodash.uniqBy(VisitContext.savedVisit.visitDeclaration.previousPorts.map(p => p.port), p => p.locationUnCode);
  }

  static findSizeTypes = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindSizeTypes", {term: term});
  static findTerminals = (term: string, portUnCode?: string) => sendQuery("com.portbase.bezoekschip.common.api.visit.FindTerminals", <FindTerminals>{term: term,portUnCode: portUnCode});
  static sizeTypeFormatter = (value: SizeType) => value ? (value.name || '') + (value.code === value.name ? '' : ' – ' + value.code) : '';
  static storageTypes: StorageType[] = [StorageType.C, StorageType.D, StorageType.E, StorageType.I, StorageType.U];
  static findWarehouseLicenses = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindMyWarehouseLicenses", {term: term});
  static warehouseLicenseFormatter = (value: WarehouseLicense) => value ? value.licenseNumber + (value.holderName ? ' – ' + value.holderName : '') : '';
  static findTransitTemplates = (term: string, organisationShortName: string) => sendQuery("com.portbase.bezoekschip.common.api.transit.FindTransitTemplates", <FindTransitTemplates>{
    term: term,
    ownerShortName: AppContext.isAdmin() && organisationShortName ?
      organisationShortName : AppContext.userProfile.organisation?.shortName
  });
  static transitTemplateFormatter = (value: TransitTemplate) => value ? value.name : '';
  static findParties = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindMyParties", {term: term});
  static partyFormatter = (value: Party) => value && value.name
    ? toTitleCase(value.name) + (value.eoriNumber ? ' — ' + value.eoriNumber : '')
    : value && value.eoriNumber ? value.eoriNumber : '';
  static findTransitParties = (term: string, organisationShortName: string) => sendQuery("com.portbase.bezoekschip.common.api.transit.FindTransitParties", <FindTransitParties>{
    term: term,
    ownerShortName: AppContext.isAdmin() && organisationShortName ?
      organisationShortName : AppContext.userProfile.organisation?.shortName
  });
  static transitPartyFormatter = (value: TransitParty) => value && value.name
    ? toTitleCase(value.name) + (value.eoriNumber ? ' — ' + value.eoriNumber : '')
    : value && value.eoriNumber ? value.eoriNumber : '';
  static terminalFormatter = (terminal: Terminal) => terminal ? toTitleCase(terminal.terminalName) + ' – ' + terminal.terminalCode : '';
  static findClassifications = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindGoodsClassifications", {term: term});
  static goodsClassificationFormatter = (value: GoodsClassification) => value ? (value.description? value.code + ' – ' + value.description :value.code): '';
  static goodsClassificationCompareFormatter = (value: GoodsClassification) => value ? value.code : '';
  static findDangerInformation = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindDangerInformation", {term: term});
  static goodsDocumentOptions = <GoodsDocumentType[]>[GoodsDocumentType.N703, GoodsDocumentType.N705,
    GoodsDocumentType.Y031, GoodsDocumentType._820, GoodsDocumentType._821, GoodsDocumentType._822];
  static containerTypeOptions = <ContainerType[]>[ContainerType.CHASSIS, ContainerType.CONTAINER,
    ContainerType.SWAP_BODY, ContainerType.TRAILER];
  static isContainerNumber = s => PortvisitUtils.containerNumberRegex.test(s);

  //ports and way points
  static findPort = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindPorts", {term: term});
  static findLocation = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindLocations", {term: term});
  static locationFormatter = (v: Location) => v ? toTitleCase(v.name) + ' – ' + (v.locationUnCode ? v.locationUnCode : v.countryUnCode) : '';
  static findPortOrWayPoint = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindPortsOrWayPoints", {term: term});
  static portFormatter = (port: Port) => port ? toTitleCase(port.name) + ' – ' + port.locationUnCode : '';
  static portFormatterCompact = (port: Port) => port ? toTitleCase(port.name) : '';

  //waste
  static findWasteMaterials = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindWasteMaterials", {term: term});

  //dangerous goods
  static findTankGood = term => sendQuery('com.portbase.bezoekschip.common.api.dangerousgoods.FindDangerousGoods',
    {term: term, stowageType: 'tank', untyped: true});
  static findContainerGood = term => sendQuery('com.portbase.bezoekschip.common.api.dangerousgoods.FindDangerousGoods',
    {term: term, stowageType: 'container', untyped: true});
  static findHoldGood = term => sendQuery('com.portbase.bezoekschip.common.api.dangerousgoods.FindDangerousGoods',
    {term: term, stowageType: 'hold', untyped: true});
  static findDangerousGoods = term => sendQuery('com.portbase.bezoekschip.common.api.dangerousgoods.FindDangerousGoods',
    {term: term, untyped: true});

  static dangerousGoodsFormatter = (value: any) => value ?
  value.name + (value.unCode ? ' - ' + value.unCode : '') + ' (' +value.type + ')'
  : '';

  static goodDataFormatter = (value: GoodDataUnion) => value ? toTitleCase(value.name)
    + ((<any>value).unCode ? ' – UN ' + (<any>value).unCode : '')
    + ((<any>value).hazardClass ? ' – Class ' + (<any>value).hazardClass : '')
    + ((<any>value).packingGroup ? ' – Packing group ' + renderPackingGroup((<any>value).packingGroup) : '')
    + ((<any>value).pollutionCategory ? ' – ' + (<any>value).pollutionCategory : '')
    : '';

  static dangerInformationFormatter = (value: DangerInformation) => MiddleEllipsisPipe.format(value ?
    value.name ? (value.unCode + ' – ' + toTitleCase(value.name)
      + ((<any>value).hazardClass ? ' – Class ' + (<any>value).hazardClass : '')
      + ((<any>value).packingGroup ? ' – Packing group ' + renderPackingGroup((<any>value).packingGroup) : ''))
      + ((<any>value).pollutionCategory ? ' – ' + (<any>value).pollutionCategory : '')
      : value.unCode
    : '', 50);

  static containerGoodDataFormatterFull = (value: GoodDataUnion) => value ? ((<any>value).unCode ? ' UN ' + (<any>value).unCode : '')
    + ((<any>value).hazardClass ? ' – Class ' + (<any>value).hazardClass : '')
    + ((<any>value).packingGroup ? ' – Packing group ' + renderPackingGroup((<any>value).packingGroup) : '')
    + (' – ' + toTitleCase(value.name))
    : '';
  static containerGoodDataFormatter = (value: GoodDataUnion) => value ? (<any>value).unCode
    + ((<any>value).packingGroup ? ' – ' + renderPackingGroup((<any>value).packingGroup) : '')
    + (' – ' + toTitleCase((<any>value).name))
    : '';
  static getTransitPreviousDocumentTypes: Observable<TransitDocumentType> = sendQuery("com.portbase.bezoekschip.common.api.visit.GetTransitPreviousDocumentTypes", {});
  static getTransitPreviousDocumentExportTypes: Observable<TransitDocumentType> = sendQuery("com.portbase.bezoekschip.common.api.visit.GetTransitPreviousDocumentExportTypes", {});
  static getTransitTransportDocumentTypes : Observable<TransitDocumentType> = sendQuery("com.portbase.bezoekschip.common.api.visit.GetTransitTransportDocumentTypes", {});
  static getTransitProducedDocumentTypes = sendQuery("com.portbase.bezoekschip.common.api.visit.GetTransitProducedDocumentTypes", {});
  static transitDocumentTypesFormatter = (value: TransitDocumentType) => value ? (value.description ? value.code + ' – '
    + toTitleCase(value.description) + (!value["export"] ? "" : " (EXPORT)") : value.code) : '';
  static transitDocumentTypesCompareFormatter = (value: TransitDocumentType) => value ? value.code : '';
  static getPackageTypes : Observable<PackageType> = sendQuery("com.portbase.bezoekschip.common.api.visit.GetPackageTypes", {});
  static getCargoPackageTypes = sendQuery("com.portbase.bezoekschip.common.api.visit.GetCargoPackageTypes", {});
  static packageTypeFormatter = (value: PackageType) => value ? (value.name? value.code + ' – ' + toTitleCase(value.name)  : value.code ): '';
  static packageTypeCompareFormatter = (value: PackageType) => value ? value.code : '';

  static tankStatuses: TankStatus[] = [TankStatus.NOT_EMPTY, TankStatus.NOT_EMPTY_INERT, TankStatus.RESIDUE,
    TankStatus.RESIDUE_INERT, TankStatus.EMPTY, TankStatus.EMPTY_NOT_GAS_FREE, TankStatus.EMPTY_INERT,
    TankStatus.EMPTY_INERT_NOT_GAS_FREE];
  static tankStatusFormatter = (value: TankStatus) => {
    switch (value) {
      case 'NOT_EMPTY':
        return 'Not empty';
      case 'NOT_EMPTY_INERT':
        return 'Not empty, inert';
      case 'RESIDUE':
        return 'Residue';
      case 'RESIDUE_INERT':
        return 'Residue, inert';
      case 'EMPTY':
        return 'Empty';
      case 'EMPTY_NOT_GAS_FREE':
        return 'Empty, not gas free';
      case 'EMPTY_INERT':
        return 'Empty, inert';
      case 'EMPTY_INERT_NOT_GAS_FREE':
        return 'Empty, inert, not gas free'
    }
  };
  static fumigants: Fumigant[] = [Fumigant.AMMONIA, Fumigant.CO, Fumigant.CO2, Fumigant.CHLOROPICRIN,
    Fumigant.FORMALDEHYDE, Fumigant.METHYLBROMIDE, Fumigant.PHOSPHINE, Fumigant.SULFURYL_FLUORIDE, Fumigant.N2];
  static fumigantFormatter = (value: Fumigant) => {
    switch (value) {
      case 'AMMONIA':
        return 'Ammonia';
      case 'CO':
        return 'Carbon monoxide';
      case 'CO2':
        return 'Carbon dioxide';
      case 'CHLOROPICRIN':
        return 'Chloropicrin';
      case 'FORMALDEHYDE':
        return 'Formaldehyde';
      case 'METHYLBROMIDE':
        return 'Methyl bromide';
      case 'PHOSPHINE':
        return 'Phosphine';
      case 'SULFURYL_FLUORIDE':
        return 'Sulfuryl fluoride';
      case 'N2':
        return 'Nitrogen';
    }
  };
  static packingGroups: PackingGroup[] = [PackingGroup.MINOR_DANGER, PackingGroup.MEDIUM_DANGER,
    PackingGroup.GREAT_DANGER];
  static packingGroupFormatter = (value: PackingGroup) => {
    switch (value) {
      case "GREAT_DANGER":
        return 'I (great danger)';
      case "MEDIUM_DANGER":
        return 'II (medium danger)';
      case "MINOR_DANGER":
        return 'III (minor danger)';
    }
  };
  static findRadionuclide = term => sendQuery("com.portbase.bezoekschip.common.api.dangerousgoods.FindRadionuclides", {term: term});
  static packageCategories: PackageCategory[] = [PackageCategory.CATEGORY_1, PackageCategory.CATEGORY_2, PackageCategory.CATEGORY_3];
  static packageCategoryFormatter = (value: PackageCategory) => {
    switch (value) {
      case "CATEGORY_1":
        return 'Category 1';
      case "CATEGORY_2":
        return 'Category 2';
      case "CATEGORY_3":
        return 'Category 3';
    }
  };
  static transportIndexes: TransportIndex[] = [TransportIndex.II, TransportIndex.III];
  static transportIndexFormatter = (value: TransportIndex) => {
    switch (value) {
      case "II":
        return 'Index II';
      case "III":
        return 'Index III';
    }
  };

  static isInvalid(value: any): boolean {
    if (lodash.isArray(value)) {
      return value.some(v => v.ngInvalid);
    }
    return value && value.ngInvalid;
  }

  static fetchProcessIdAndOpenMessageManagement(entityId: string) {
    sendQuery("com.portbase.bezoekschip.common.api.messaging.GetProcessId",
      {"entityId" : entityId}, {showSpinner: true, responseType: "text/plain"}).subscribe(processId => {
      if (!processId) {
        AppContext.registerError("Process id for message management could not be obtained");
      } else {
        this.redirectToMessageManagement(processId);
      }
    });
  }

  static fetchCoreorOutProcessIdAndOpenMessageManagement(releaseId: string) {
    sendQuery("com.portbase.bezoekschip.common.api.messaging.GetCoreorOutProcessId",
      {"releaseId" : releaseId}, {showSpinner: true, responseType: "text/plain"}).subscribe(processId => {
      if (!processId) {
        AppContext.registerError("Process id for message management could not be obtained");
      } else {
        this.redirectToMessageManagement(processId);
      }
    });
  }

  static getKibanaLink(searchTerm: string, eventTime: string) {
    const baseUrl = AppContext.environment === "localhost"
      ? "http://localhost:5601/"
      : `https://kibana.portvisit.${AppContext.environment}.portbase.com/`;
    const time = moment(eventTime).subtract(1, 'hour');
    const differenceInHour = moment().diff(time, 'hours', false);
    return baseUrl + `_plugin/kibana/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-${differenceInHour}h,to:now))&_a=(columns:!(type,messageType),filters:!(),interval:auto,query:(language:kuery,query:%22${encodeURI(searchTerm)}%22),sort:!())`;
  }

  static redirectToMessageManagement(processId: string) {
    const form = document.createElement('form');
    form.action = 'https://www.' + AppContext.environment + '.portbase.com/messagecontrol/searchmessage.do';
    form.method = 'POST';
    form.target = 'messagemanagement';

    const processData = {
      currentActionRouter: 'messageOverview',
      actionRouter: 'messageOverview',
      actionName: 'filterMessagesPM',
      actionValue: true,
      screenId: 'S087',
      fndMessageCreationFromDateTime: moment().add(-3650, 'days').format('DD-MM-YYYY HH:mm'),
      fndMessageCreationToDateTime: moment().add(2, 'days').format('DD-MM-YYYY HH:mm'),
      fndProcessId: processId,
      fndMessageReference: '',
      deeplink: true
    };
    for (const key in processData) {
      const input = document.createElement('textarea');
      input.name = key;
      input.value = typeof processData[key] === 'object' ? JSON.stringify(processData[key]) : processData[key];
      form.appendChild(input);
    }
    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
  }
}

function renderPackingGroup(packingGroup: PackingGroup) {
  switch (packingGroup) {
    case 'GREAT_DANGER':
      return 'I';
    case 'MEDIUM_DANGER':
      return 'II';
    case 'MINOR_DANGER':
      return 'III';
  }
}
