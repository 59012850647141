<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason type">
          <app-select [(ngModel)]="exemption['reasonType']" id="reasonType" [options]="reasonTypes"
                      [formatter]="reasonTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'OTHER'" label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Condition of Cargo tanks">
          <app-select [(ngModel)]="exemption['conditionType']" id="conditionType" [options]="conditionTypes"
                      [formatter]="conditionTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-info *ngIf="exemption['conditionType'] === 'EMPTY_AND_CLEAN' || exemption['conditionType'] === 'LOADED_OR_NOT_GAS_FREE'" class="formGroupLabel">
          <div class="tooltipContent">If all cargo tanks meet these conditions, additional information is required. Add the stowage plan and/or final loads specification below. If these documents are not present, please specify the loads in the field below.</div>
        </app-info>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is there or will a report of findings be issued by a recognized gas expert before berthing is taken ?">
          <app-yes-no [(ngModel)]="exemption['thereIsReportOfFindings']" id="thereIsReportOfFindings" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Tanks, tank number, last cargo, type of tank (Slop or Cargo)">
          <input [(ngModel)]="exemption['tanks']" id="tanks" type="text" maxlength="256" class="form-control">
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Are there inert tanks available ?">
          <app-yes-no [(ngModel)]="exemption['thereAreInertTanks']" id="thereAreInertTanks" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['thereAreInertTanks']" label="Tank">
          <app-search [(ngModel)]="exemption['inertTanksDescription']" dataKey="name"
                      [searchFunction]="portVisitUtils.findTankGood"
                      [resultFormatter]="dangerInformationFormatter"
                      placeholder="Find by name">
          </app-search>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
  </ng-container>
</ng-container>
