<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>First vessel name/IMO registration</label>
            <div class="tooltipContent">Name of moored vessel 1</div>
          </app-info>
          <app-search [(ngModel)]="firstVessel" (ngModelChange)="onVesselChange('first', firstVessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                      [minCharacters]="3" dataKey="imoCode" id="firstVessel">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="First vessel name">
          <input [(ngModel)]="exemption['firstVesselName']" id="firstVesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
        <app-form-group label="First vessel IMO">
          <input [(ngModel)]="exemption['firstVesselIMO']" id="firstVesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <label>First vessel berthing/admission time</label>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>From</label>
            <div class="tooltipContent">From when to when is the vessel berthed next to the ‘base vessel’</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['firstVesselFrom']" id="firstVesselFrom" required></app-date-field>
        </app-form-group>
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>To</label>
            <div class="tooltipContent">From when to when is the vessel berthed next to the ‘base vessel’</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['firstVesselTo']" id="firstVesselTo" required></app-date-field>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset" *ngIf="exemption['firstVesselName']">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Second vessel name/IMO registration</label>
            <div class="tooltipContent">Name of moored vessel 2</div>
          </app-info>
          <app-search [(ngModel)]="secondVessel" (ngModelChange)="onVesselChange('second', secondVessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                      [minCharacters]="3" dataKey="imoCode" id="secondVessel">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Second vessel name">
          <input [(ngModel)]="exemption['secondVesselName']" id="secondVesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
        <app-form-group label="Second vessel IMO">
          <input [(ngModel)]="exemption['secondVesselIMO']" id="secondVesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset" *ngIf="exemption['secondVesselName']">
      <div class="col-md">
        <app-form-group>
          <label>Second vessel berthing/admission time</label>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>From</label>
            <div class="tooltipContent">From when to when is the vessel berthed next to the ‘base vessel’</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['secondVesselFrom']" id="secondVesselFrom" required></app-date-field>
        </app-form-group>
        <app-form-group label="To">
          <app-info class="formGroupLabel">
            <label>To</label>
            <div class="tooltipContent">From when to when is the vessel berthed next to the ‘base vessel’</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['secondVesselTo']" id="secondVesselTo" required></app-date-field>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset" *ngIf="exemption['secondVesselName']">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Third vessel name/IMO registration</label>
            <div class="tooltipContent">Name of moored vessel 3</div>
          </app-info>
          <app-search [(ngModel)]="thirdVessel" (ngModelChange)="onVesselChange('third', thirdVessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                      [minCharacters]="3" dataKey="imoCode" id="thirdVessel">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Third vessel name">
          <input [(ngModel)]="exemption['thirdVesselName']" id="thirdVesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
        <app-form-group label="Third vessel IMO">
          <input [(ngModel)]="exemption['thirdVesselIMO']" id="thirdVesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset" *ngIf="exemption['secondVesselName']">
      <div class="col-md">
        <app-form-group>
          <label>Third vessel berthing/admission time</label>
        </app-form-group>
      </div>
      <div class="col-md" *ngIf="exemption['thirdVesselName']">
        <app-form-group label="From">
          <app-info class="formGroupLabel">
            <label>From</label>
            <div class="tooltipContent">From when to when is the vessel berthed next to the ‘base vessel’</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['thirdVesselFrom']" id="thirdVesselFrom" required></app-date-field>
        </app-form-group>
        <app-form-group label="To">
          <app-info class="formGroupLabel">
            <label>To</label>
            <div class="tooltipContent">From when to when is the vessel berthed next to the ‘base vessel’</div>
          </app-info>
          <app-date-field [(ngModel)]="exemption['thirdVesselTo']" id="thirdVesselTo" required></app-date-field>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Substance (Proper shipping name)">
          <app-search [(ngModel)]="dangerousGoods" dataKey="name"
                      (ngModelChange)="onDangerousGoodsChange(dangerousGoods)"
                      [inputFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      [searchFunction]="portVisitUtils.findDangerousGoods"
                      [resultFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      placeholder="Find by name or UN code">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Substance name">
          <textarea [(ngModel)]="exemption['substanceName']" id="substanceName" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
      </div>
      <div class="col-md">
        <app-form-group label="UN or VN number">
          <input [(ngModel)]="exemption['unVnNumber']" id="unVnNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
