<ng-container *ngIf="goodsItem">
  <app-consignment-details-alerts [consignmentProcess]="consignmentProcess"></app-consignment-details-alerts>
  <div class="modal-header">
    <div class="flex-container align-items-center">
      <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="d-inline-flex me-1">
        <i class="fa fa-fw fa-light fa-lg fa-file-lines text-muted"></i>
      </div>
      <button class="btn btn-link p-0 page-title align-baseline" (click)="processModelAndOpenMasterConsignment()">
        <span class="page-title">{{consignmentProcess.consignmentMasterLevel.consignmentNumber}}</span>
      </button>
      <div class="d-inline-flex mx-2">
        <i class="fa fa-fw fa-light fa-lg fa-chevron-right text-muted"></i>
      </div>
      <ng-container *ngIf="houseConsignment">
        <div class="d-inline-flex me-1">
          <i class="fa fa-kit fa-fw fa-light fa-lg fa-pb-file-line text-muted"></i>
        </div>
        <button class="btn btn-link p-0 page-title align-baseline" (click)="processModelAndOpenHouseConsignment()">
          <span class="page-title">{{ houseConsignment.consignmentNumber }}</span>
        </button>
        <div class="d-inline-flex mx-2">
          <i class="fa fa-fw fa-light fa-lg fa-chevron-right text-muted"></i>
        </div>
      </ng-container>
      <div class="d-inline-flex me-1">
        <i class="fa fa-fw fa-light fa-lg fa-box-open-full text-muted"></i>
      </div>
      <span class="page-title">{{goodsItem.commodity?.descriptionOfGoods | middleEllipsis}}</span>
    </div>
  </div>

  <div class="modal-body p-0">
    <app-tab-panel [sticky]="true" [alwaysRender]="true">
      <app-tab-item [templateRef]="detailsRef">
        <span class="tab-item">Details</span>
        <ng-template #detailsRef>
          <div class="container py-3">
            <div class="card">
              <div class="card-body p-5">
                <div class="row gy-4 my-0">
                  <h5 class="h1 col-12 m-0">Goods details</h5>
                  <span *ngIf="editMode">If you add a CN code, we can automatically match and prefill the ‘Customs Union and Statistics number’ and ‘The United Nations Dangerous Goods identifier number’.</span>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="HS or CN code" class="col-12"
                                      [(value)]="goodsItem.commodity" [editMode]="editMode"
                                      [includeMargin]="false" [formatter]="utils.commodityFormatter">
                        <div class="d-block mb-2">
                          <span class="text-secondary">Harmonized System sub–heading code (6 digits) or Combined nomenclature code (8 digits)</span>
                        </div>
                        <app-search [(ngModel)]="goodsItem.commodity" [inputFormatter]="utils.commodityFormatter"
                                    [searchFunction]="utils.findCommodities" [minCharacters]="1" required
                                    (ngModelChange)="goodsItem.commodity = $event || {}" dataKey="code" placeholder="Select a HS or CN code"></app-search>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="UNDG number" class="col-12"
                                      [(value)]="goodsItem.commodity.dangerousGoods" [editMode]="editMode"
                                      [includeMargin]="false" [formatter]="refData.dangerInformationFormatter">
                        <div class="d-block mb-2">
                          <span class="text-secondary">The United Nations Dangerous Goods identifier number</span>
                        </div>
                        <app-search [(ngModel)]="goodsItem.commodity.dangerousGoods" dataKey="name"
                                    [inputFormatter]="refData.dangerInformationFormatter"
                                    [searchFunction]="refData.findDangerInformation"
                                    [resultFormatter]="refData.dangerInformationFormatter"
                                    placeholder="Find by name or UN code"></app-search>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="CUS number" class="col-12"
                                      [(value)]="goodsItem.commodity.cusCode" [editMode]="editMode"
                                      [includeMargin]="false">
                        <div class="d-block mb-2">
                          <span class="text-secondary">Customs Union and Statistics number is the identifier assigned
                            within the European Customs Inventory of Chemical Substances (ECICS) to mainly chemical
                            substances and preparations</span>
                        </div>
                        <input class="input-group rounded border form-control" [(ngModel)]="goodsItem.commodity.cusCode"/>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Gross weight (kg)" class="col-6 col-md-3"
                                      [(value)]="goodsItem.grossWeight" [editMode]="editMode"
                                      [includeMargin]="false" [compare]="bulkDischargeWeight"
                                      compareHeader="New data from Terminal">
                        <input class="input-group rounded border form-control" type="number" step="any" required
                               [(ngModel)]="goodsItem.grossWeight"/>
                      </app-form-field>
                      <app-form-field label="Net weight (kg)" class="col-6 col-md-3"
                                      [(value)]="goodsItem.netWeight" [editMode]="editMode"
                                      [includeMargin]="false">
                        <input class="input-group rounded border form-control" type="number" step="any"
                               [(ngModel)]="goodsItem.netWeight"/>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Minimum temperature (°C)" class="col col-md-5"
                                      [(value)]="goodsItem.minimumTemperature" [editMode]="editMode"
                                      [includeMargin]="false">
                        <input class="input-group rounded border form-control" type="number"
                               [(ngModel)]="goodsItem.minimumTemperature"/>
                      </app-form-field>
                      <app-form-field label="Maximum temperature (°C)" class="col col-md-5"
                                      [(value)]="goodsItem.maximumTemperature" [editMode]="editMode"
                                      [includeMargin]="false">
                        <input class="input-group rounded border form-control" type="number"
                               [(ngModel)]="goodsItem.maximumTemperature"/>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <app-goods-packaging [packaging]="goodsItem.outerPackaging" [editMode]="editMode"
                                         label="Outer package type"></app-goods-packaging>
                  </div>
                  <div *ngIf="goodsItem.outerPackaging.typeOfPackages && !goodsItem.outerPackaging.typeOfPackages.bulk" class="col-12">
                    <label class="h2 fw-regular">Inner packages</label>
                    <span *ngIf="editMode" class="form-field-optional text-secondary ms-1">(optional)</span>
                    <ng-container *ngFor="let packaging of goodsItem.innerPackagingList; index as i; trackBy: refData.trackByIndex">
                      <app-goods-packaging [packaging]="packaging" [editMode]="editMode" label="Inner package type">
                        <div *ngIf="editMode" class="col-auto d-flex align-items-center pt-5">
                          <button class="btn rounded-circle p-1 px-2 text-danger h1 mb-0" (click)="deleteInnerPackage(packaging)">
                            <i class="fa-light fa-trash-can"></i>
                          </button>
                        </div>
                      </app-goods-packaging>
                    </ng-container>
                    <div *ngIf="editMode" class="row mt-3">
                      <div class="col-auto">
                        <button class="btn btn-outline-primary text-decoration-none" (click)="addInnerPackage()">
                          <i class="button-icon fa-light fa-plus"></i>
                          <span class="ps-2 d-none d-md-inline">Add inner package</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <label class="h2 fw-regular">Documents</label>
                    <span *ngIf="editMode" class="form-field-optional text-secondary ms-1">(optional)</span>
                    <div class="row" *ngIf="goodsItem.previousDocument">
                      <app-form-field label="Previous document" class="col col-md-7"
                                      [(value)]="goodsItem.previousDocument.type" [editMode]="editMode"
                                      [includeMargin]="false" [formatter]="utils.supportingDocumentTypeFormatter">
                        <app-select [(ngModel)]="goodsItem.previousDocument.type" placeholder="Select document type"
                                    [options]="utils.documentTypes" [formatter]="utils.supportingDocumentTypeFormatter"></app-select>
                      </app-form-field>
                      <app-form-field label="Reference number" [(value)]="goodsItem.previousDocument.identification" class="col col-md-3"
                                      [editMode]="editMode" [includeMargin]="false">
                        <input class="input-group rounded border form-control"
                               [(ngModel)]="goodsItem.previousDocument.identification"/>
                      </app-form-field>
                    </div>
                  </div>
                  <ng-container *ngFor="let supportingDocument of goodsItem.supportingDocuments; index as i; trackBy: refData.trackByIndex">
                    <div class="col-12">
                      <div class="row">
                        <app-form-field label="Supporting document" class="col col-md-7" [truncateReadOnly]="true"
                                        [(value)]="supportingDocument.type" [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="utils.documentTypeFormatter">
                          <app-search [(ngModel)]="supportingDocument.type" [inputFormatter]="utils.documentTypeFormatter" required
                                      [searchFunction]="utils.findDocumentTypes" placeholder="Select a document type"></app-search>
                        </app-form-field>
                        <app-form-field label="Reference number" [(value)]="supportingDocument.referenceNumber" class="col col-md-3"
                                        [editMode]="editMode" [includeMargin]="false">
                          <input class="input-group rounded border form-control" required
                                 [(ngModel)]="supportingDocument.referenceNumber"/>
                        </app-form-field>
                        <div *ngIf="editMode" class="col-auto d-flex align-items-center pt-5">
                          <button class="btn rounded-circle p-1 px-2 text-danger h1 mb-0" (click)="deleteSupportingDocument(supportingDocument)">
                            <i class="fa-light fa-trash-can"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <div *ngIf="editMode" class="row mt-3">
                      <div class="col-auto">
                        <button class="btn btn-outline-primary text-decoration-none" (click)="addSupportingDocument()">
                          <i class="button-icon fa-light fa-plus"></i>
                          <span class="ps-2 d-none d-md-inline">Add supporting document</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="equipmentRef">
        <span class="tab-item">Equipment</span>
        <ng-template #equipmentRef>
          <app-consignment-equipments-table [data]="equipmentListSummaries" [editable]="isEditable" [editMode]="editMode"
                                            [showPlacement]="true" [(goodsItem)]="goodsItem"
                                            [houseConsignmentNumber]="houseConsignment?.consignmentNumber"></app-consignment-equipments-table>
        </ng-template>
      </app-tab-item>
      <app-tab-item *ngIf="consignmentProcess.filing.filingType==='F50'" [templateRef]="vehiclesRef">
        <span class="tab-item">Vehicles</span>
        <ng-template #vehiclesRef>
          <app-consignment-vehicles-table [data]="goodsItem.passiveBorderTransportMeans" [editable]="isEditable"
                                          [(editMode)]="editMode"></app-consignment-vehicles-table>
        </ng-template>
      </app-tab-item>
    </app-tab-panel>
  </div>

  <div class="modal-footer" *ngIf="!consignmentProcess.cancelled">
    <div class="d-flex flex-grow-1">
      <div class="container">
        <div class="row mx-4">
          <div class="col-auto">
            <button type="button" class="btn btn-outline-danger" (click)="remove()">Remove</button>
          </div>
          <div class="col-auto ms-auto">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close"
                    *ngIf="!editMode">
              Close
            </button>
            <button type="button" class="btn btn-outline-secondary" *ngIf="editMode" (click)="toggleEdit()">
              Cancel
            </button>
          </div>
          <div class="col-auto">
            <ng-container *ngIf="editMode else readMode">
              <button type="button" class="btn btn-primary" (click)="toggleEdit()" *ngIf="hasBeenDeclared">
                Ok
              </button>
              <button *ngIf="!hasBeenDeclared" type="button" class="btn btn-primary" (click)="saveOrDeclare()">
                Save
              </button>
            </ng-container>
            <ng-template #readMode>
              <button *ngIf="isEditable" type="button" class="btn btn-outline-secondary me-4" (click)="toggleEdit()">
                Edit
              </button>
              <button *ngIf="allowedToDeclare && hasBeenDeclared" type="button" class="btn btn-primary" (click)="saveOrDeclare()">
                Update
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="w-100 h-100 position-absolute d-flex flex-column-reverse" [ngClass]="showSubModal ? '' : 'd-none'">
  <div class="w-100 h-100 position-absolute modal-backdrop show" (click)="closeConsignmentSubModal()"></div>
  <div class="bg-body" style="z-index: 1050; height: calc(100% - 110px);">
    <ng-template #subModalContainer></ng-template>
  </div>
</div>
