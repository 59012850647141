import {ElementRef} from '@angular/core';
import {HandlerRegistry} from "./handler-registry.service";
import {View} from './view';
import {InjectorProvider} from "../injector-provider";


export function Handler() {
  return function (target: any) {
    const originalNgOnInit = target.prototype.ngOnInit;
    const originalNgOnDestroy = target.prototype.ngOnDestroy;

    // Override ngOnInit to execute custom logic
    target.prototype.ngOnInit = function (...args: any[]) {
      const elementRef : ElementRef = this instanceof View ? (<View> this).elementRef : (this["elementRef"] || null);
      this.cancelRegistration = InjectorProvider.injector.get(HandlerRegistry).registerHandlerInstance(
        Object.getPrototypeOf(this).constructor.name, this, elementRef);

      // Call the original ngOnInit
      if (originalNgOnInit) {
        originalNgOnInit.apply(this, args);
      }
    };

    // Override ngOnDestroy to execute custom logic
    target.prototype.ngOnDestroy = function (...args: any[]) {
      this.cancelRegistration();

      // Call the original ngOnDestroy
      if (originalNgOnDestroy) {
        originalNgOnDestroy.apply(this, args);
      }
    };
  };
}

export interface Handler {
  instance: any;
  invoker: HandlerInvoker;
}

export interface HandlerInvoker {
  targetClassName: any;
  type: string;
  method: Function;
  messageType: 'event' | 'query' | 'command';
  options: HandlerOptions;
}

export interface HandlerOptions {
  caching?: boolean;
  global?: boolean;
}
