import {take} from "rxjs/operators";
import {Observable, Subscriber} from "rxjs";
import {QueryGateway} from "./query-gateway";
import {ElementRef} from '@angular/core';
import {CommandGateway, CommandOptions} from './command-gateway';
import {EventGateway} from './event-gateway';
import {RequestOptions} from './request-gateway';
import {AppContext} from "../../app-context";
import {InjectorProvider} from "../injector-provider";

export function subscribeTo(type: string, payload = {}, options ?: RequestOptions, elementRef? : ElementRef<Element>): Observable<any> {
  if (!InjectorProvider.injector) {
    return new Observable((subscriber: Subscriber<any>) => {
      InjectorProvider.injector.get(QueryGateway).subscribe(type, payload, options, elementRef).subscribe(subscriber);
    });
  }
  return InjectorProvider.injector.get(QueryGateway).subscribe(type, payload, options, elementRef);
}

export function sendHandlerQuery(type: string, payload = {}, options ?: RequestOptions, elementRef? : ElementRef<Element>): Observable<any> {
  if (!InjectorProvider.injector) {
    return new Observable((subscriber: Subscriber<any>) => {
      InjectorProvider.injector.get(QueryGateway).send(type, payload, options, elementRef).subscribe(subscriber);
    });
  }
  return InjectorProvider.injector.get(QueryGateway).send(type, payload, options, elementRef);
}

export function sendHandlerCommand(type: string, payload: any,
                                   successHandler?: (value: any) => void,
                                   errorHandler?: (error: any) => void, options ?: CommandOptions,
                                   elementRef? : ElementRef): void {
  AppContext.clearAlerts();
  AppContext.waitForProcess(InjectorProvider.injector.get(CommandGateway).send(type, payload, options, elementRef).pipe(take(1)))
    .subscribe({
      next: successHandler ? successHandler : () => AppContext.registerSuccess("Operation has completed successfully"),
      error: errorHandler ? errorHandler : (error) => AppContext.registerError(error)
    });
}

export function sendCommandAndForget(type: string, payload: any = {}, options ?: CommandOptions, elementRef? : ElementRef): void {
  InjectorProvider.injector.get(CommandGateway).sendAndForget(type, payload, options, elementRef);
}

export function publishHandlerEvent(type: string, payload?: any, elementRef? : ElementRef) {
  InjectorProvider.injector.get(EventGateway).publish(type, payload || {}, elementRef);
}
