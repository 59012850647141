import {Component} from '@angular/core';
import {
  DangerInformation,
  Exemption_6_3_1,
  Exemption_6_3_1_RelationType, Vessel
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ExemptionBaseComponent} from "../exemption_base/exemption_base.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {sendQuery} from "../../../../../common/utils";

@Component({
  selector: 'app-visit-exemptions-exemption-6-3-1',
  templateUrl: './exemption_6_3_1.component.html',
  styleUrls: ['./exemption_6_3_1.component.css']
})
export class Exemption_6_3_1Component extends ExemptionBaseComponent {

  exemptionSubType: Exemption_6_3_1;

  dangerousGoods?: DangerInformation;
  portVisitUtils = PortvisitUtils;

  vessel: Vessel;

  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});

  onDangerousGoodsChange(dangerousGoods: DangerInformation) {
    this.exemption['substanceName'] = dangerousGoods.name;
    this.exemption['unVnNumber'] = dangerousGoods.unCode;
  }

  relationTypes: Exemption_6_3_1_RelationType[] = [
    Exemption_6_3_1_RelationType.SUBSTANCES_LOW_VAPOUR_PRESSURE,
    Exemption_6_3_1_RelationType.FOR_USE_OF_VAPOUR,
    Exemption_6_3_1_RelationType.CARGO_TANK_IN_QUESTION,
    Exemption_6_3_1_RelationType.VAPOURS_CAPTURED_IN_OTHER_WAY
  ];

  relationTypesFormatter = (relationType: Exemption_6_3_1_RelationType) => {
    switch (relationType) {
      case "SUBSTANCES_LOW_VAPOUR_PRESSURE":
        return 'The substances sodium hydroxide, potassium hydroxide, phosphoric acid, sulphuric acid, or any other substance with low vapour pressure';
      case "FOR_USE_OF_VAPOUR":
        return 'Exemption for use of vapour return in connection with uninerted seagoing vessel loading (does not apply to transhipment of substances listed in Annex I of the Port By-Laws)';
      case "CARGO_TANK_IN_QUESTION":
        return 'The cargo tank in question on the seagoing vessel is inert and the inland barge is not inert and it is a closed ADN substance';
      case "VAPOURS_CAPTURED_IN_OTHER_WAY":
        return 'The vapours are captured in another alternative way by an appropriate facility (Vapour Recovery Unit)';
    }
  };

  vesselFormatter = (vessel: Vessel) => {
    return vessel.name + ' – ' + vessel.imoCode;
  };

  onVesselChange(vessel: Vessel) {
    this.exemption['vesselName'] = vessel.name;
    this.exemption['vesselIMO'] = vessel.imoCode;
  }
}

