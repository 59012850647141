<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>(2nd) Vessel name/registration</label>
            <div class="tooltipContent">Name of additional vessel; only applicable if berth dimensions are exceeded by mooring additional vessel.</div>
          </app-info>
          <app-search [(ngModel)]="secondVessel" (ngModelChange)="onVesselChange('second', secondVessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                    [minCharacters]="3" dataKey="imoCode" id="secondVessel">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group>
          <app-info *ngIf="exemption['secondVesselName']" class="formGroupLabel">
            <label>(3rd) Vessel name/registration</label>
            <div class="tooltipContent">Name of additional vessel; only applicable if berth dimensions are exceeded by mooring additional vessel.</div>
          </app-info>
          <app-search *ngIf="exemption['secondVesselName']" [(ngModel)]="thirdVessel" (ngModelChange)="onVesselChange('third', thirdVessel)" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                      [minCharacters]="3" dataKey="imoCode" id="thirdVessel">
          </app-search>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="(2nd) Name">
          <input [(ngModel)]="exemption['secondVesselName']" id="secondVesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['secondVesselName']"  label="(3rd) Name">
          <input [(ngModel)]="exemption['thirdVesselName']" id="thirdVesselName" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="(2nd) IMO Number">
          <input [(ngModel)]="exemption['secondVesselIMO']" id="secondVesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['secondVesselName']" label="(3rd) IMO Number">
          <input [(ngModel)]="exemption['thirdVesselIMO']" id="thirdVesselIMO" type="text" rows="3" maxlength="64" class="form-control" readonly>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['secondVesselName']" label="(2nd) CRN">
          <input [(ngModel)]="exemption['secondVesselCRN']" id="secondVesselCRN" type="text" rows="3" maxlength="64" class="form-control">
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['thirdVesselName']" label="(3rd) CRN">
          <input [(ngModel)]="exemption['thirdVesselCRN']" id="thirdVesselCRN" type="text" rows="3" maxlength="64" class="form-control">
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['secondVesselName']" label="(2nd) Arrival">
          <app-date-field [(ngModel)]="exemption['secondVesselArrival']" id="secondVesselArrival"></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['thirdVesselName']" label="(3rd) Arrival">
          <app-date-field [(ngModel)]="exemption['thirdVesselArrival']" id="thirdVesselArrival"></app-date-field>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['secondVesselName']" label="(2nd) Departure">
          <app-date-field [(ngModel)]="exemption['secondVesselDeparture']" id="secondVesselDeparture"></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['thirdVesselName']" label="(3rd) Departure">
          <app-date-field [(ngModel)]="exemption['thirdVesselDeparture']" id="thirdVesselDeparture"></app-date-field>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Total distance (m)</label>
            <div class="tooltipContent">Largest combination of vessel(s) + possibly fendering.</div>
          </app-info>
          <input [(ngModel)]="exemption['totalDistance']" id="totalDistance" type="number" rows="3" maxlength="64" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <app-select id="reasonType" [(ngModel)]="exemption['reasonType']" [options]="reasonTypes"
                      [formatter]="reasonTypesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'OTHER_VESSELS'" label="What kind of activity will take place ?">
          <app-select id="reasonTypeActivity" [(ngModel)]="exemption['reasonTypeActivity']" [options]="reasonTypeActivities"
                      [formatter]="reasonTypeActivitiesFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Other reason (Activity)" *ngIf="exemption['reasonTypeActivity'] === 'OTHER'">
          <input [(ngModel)]="exemption['reasonTypeActivityDescription']" id="reasonTypeActivityDescription" type="text" rows="3" minlength="10" maxlength="64" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group *ngIf="exemption['reasonType'] === 'OTHER_VESSELS'" label="Which vessel(s) is/are also part of exceedance (in addition to those specified ?)">
          <app-select id="reasonTypeVessel" [(ngModel)]="exemption['reasonTypeVessel']" [options]="reasonTypeVessels"
                      [formatter]="reasonTypeVesselsFormatter" required>
          </app-select>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Other vessel" *ngIf="exemption['reasonTypeVessel'] === 'OTHER'">
          <input [(ngModel)]="exemption['reasonTypeVesselDescription']" id="reasonTypeVesselDescription" type="text" rows="3" minlength="10" maxlength="64" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="The undersigned declares that the information in this request form has been filled in truthfully and that any explanatory notes and/or attachments reflect reality">
          <app-yes-no [(ngModel)]="exemption['hasFormFilledTruthfully']" id="hasFormFilledTruthfully" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="To guarantee passage to surrounding (shore) companies, I agree with the liability for all costs likely to be incurred owing to berth dimensions being exceeded">
          <app-yes-no [(ngModel)]="exemption['agreedWithTheLiability']" id="agreedWithTheLiability" required></app-yes-no>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="I agree to cooperate fully if shifting is deemed necessary to guarantee passage">
          <app-yes-no [(ngModel)]="exemption['agreedToCooperate']" id="agreedToCooperate" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="From the moment permission has been withdrawn by the HCC, the captain or skipper must ensure that the established maximum permitted width is restored within a maximum time-scale of 2 hours, or by a time to be specified by the HCC">
          <app-yes-no [(ngModel)]="exemption['permittedWidthRestored']" id="permittedWidthRestored" required></app-yes-no>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="The following shore-based businesses have been informed in advance by or on behalf of me (the applicant) (if there is a reduced accessibility for surrounding (shore-based) businesses as a result of the proposed exceedance), and have also agreed to the reduced accessibility">
          <app-yes-no [(ngModel)]="exemption['haveBusinessesBeenInformed']" id="haveBusinessesBeenInformed" required></app-yes-no>
        </app-form-group>
      </div>
    </div>
 </ng-container>
</ng-container>
