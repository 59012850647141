<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Substance name from which cargo or slop tank is cleaned">
          <app-search [(ngModel)]="dangerousGoods" dataKey="name"
                      (ngModelChange)="onDangerousGoodsChange(dangerousGoods)"
                      [inputFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      [searchFunction]="portVisitUtils.findDangerousGoods"
                      [resultFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      placeholder="Find by name or UN code" >
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>Substance name</label>
            <div class="tooltipContent">If unknown select 0000</div>
          </app-info>
          <textarea [(ngModel)]="exemption['substanceName']" id="substanceName" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
      </div>
      <div class="col-md">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label>UN or VN number</label>
            <div class="tooltipContent">If unknown select 0000</div>
          </app-info>
          <input [(ngModel)]="exemption['unVnNumber']" id="unVnNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Does the terminal or facility agree with the cleaning operations ?">
          <app-yes-no [(ngModel)]="exemption['agreedWithCleaning']" id="agreedWithCleaning" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['agreedWithCleaning']" label="Add proof that the terminal or facility is in agreement">
          <div style="display: flex; justify-content: flex-end; gap: 10px;">
            <button class="btn btn-secondary" role="button"
                    title="Upload document of proof">
              <span class="fa fa-file-upload"></span>
              <input type="file" hidden multiple>
              Upload
            </button>
            <input disabled type="text" [ngModel]="" maxlength="30" class="form-control" style="width: 225px;">
          </div>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Is the vessel equipped with an enclosed washing system ?">
          <app-yes-no [(ngModel)]="exemption['equippedWithWashingSystem']" id="equippedWithWashingSystem" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Is there a pre-wash obligation under Marpol Annex II ?">
          <app-yes-no [(ngModel)]="exemption['preWashObligated']" id="preWashObligated" required></app-yes-no>
        </app-form-group>
      </div>
    </div>

    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
