<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Type of activities">
          <input [(ngModel)]="exemption['activityType']" id="activityType" maxlength="256" type="text" class="form-control" required>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Name of company carrying out activities">
          <input [(ngModel)]="exemption['nameOfCompany']" id="nameOfCompany" maxlength="256" type="text" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="From">
          <app-date-field [(ngModel)]="exemption['from']" id="from" required></app-date-field>
        </app-form-group>
        <app-form-group label="To">
          <app-date-field [(ngModel)]="exemption['to']" id="to" required></app-date-field>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Has a task risk analysis  been drawn up ?">
          <app-yes-no [(ngModel)]="exemption['hasRiskAnalysisBeenDrawnUp']" id="hasRiskAnalysisBeenDrawnUp" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Have risks arising from the task risk analysis been identified ?">
          <app-yes-no [(ngModel)]="exemption['haveRisksBeenIdentified']" id="haveRisksBeenIdentified" required></app-yes-no>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group *ngIf="exemption['haveRisksBeenIdentified']" label="Annex">
          <div style="display: flex; justify-content: flex-end; gap: 10px;">
            <button class="btn btn-secondary" role="button"
                    title="Upload annex">
              <span class="fa fa-file-upload"></span>
              <input type="file" hidden multiple>
              Upload
            </button>
            <input disabled type="text" [ngModel]="" maxlength="30" class="form-control" style="width: 225px;">
          </div>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
