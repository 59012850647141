<ng-container>

  <div>
    <b>{{ name }}</b>
    <app-info class="formGroupLabel" [wide]="false" [placement]="'left'">
      <div class="tooltipContent">
        <b>Considering</b>
        <ul>
          <div *ngFor="let consider of splitConsidering()">
            <li>
              {{consider}}
            </li>
          </div>
        </ul>
      </div>
    </app-info>
  </div>

  <app-visit-exemptions-exemption-general [exemption]="exemption"></app-visit-exemptions-exemption-general>

  <ng-container>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Substance (Proper shipping name)">
          <app-search [(ngModel)]="dangerousGoods" dataKey="name"
                      (ngModelChange)="onDangerousGoodsChange(dangerousGoods)"
                      [inputFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      [searchFunction]="portVisitUtils.findDangerousGoods"
                      [resultFormatter]="portVisitUtils.dangerousGoodsFormatter"
                      placeholder="Find by name or UN code">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Substance name">
          <textarea [(ngModel)]="exemption['substanceName']" id="substanceName" type="text" rows="3" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
      </div>
      <div class="col-md">
        <app-form-group label="UN or VN number">
          <input [(ngModel)]="exemption['unVnNumber']" id="unVnNumber" type="text" maxlength="256" class="form-control" required>
        </app-form-group>
      </div>
    </div>
    <div class="row fieldset">
      <div class="col-md">
        <app-form-group label="Reason for request for exemption">
          <textarea [(ngModel)]="exemption['reason']" id="reason" type="text" rows="3" minlength="20" maxlength="1024" class="form-control" required></textarea>
        </app-form-group>
      </div>
      <div class="col-md">
        <app-form-group label="Details">
          <textarea [(ngModel)]="exemption['details']" id="details" type="text" rows="3" maxlength="256" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </ng-container>
</ng-container>
